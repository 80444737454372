@import "src/styles/colors";
@import "src/styles/fonts";

.errorMessage {
  color: $red;
  margin-bottom: 0;
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem !important;
  font-size: $font-size-xs;
  font-weight: bold;
}
