@import "src/styles/colors";

.layout {
  width: 100%;
}

.layoutTitle {
  font-weight: bold;
  line-height: normal;
  text-align: center;
  color: $black;
  margin: 3.75rem 0.844rem 3.125rem 1.125rem;
}
