@import "/src/styles/colors";
@import "/src/styles/breakpoints";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  
  img {
    margin-bottom: 1.875rem;
    height: 6.5rem;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
  }
}

.title {
  color: $red;
  margin-bottom: 0.9375rem;
}

.description {
  width: 13.1875rem;
  margin-bottom: 5.0625rem;
}

.buttons {
  display: flex;
  gap: 3.75rem;
}

.button {
  height: 2.8125rem;
  width: 14.9375rem;
  padding: 0;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $white;
}

@media (max-width: $device-md) {
  .buttons {
    flex-direction: column;
    gap: 0.7rem;
  }
  .description {
    margin-bottom: 3rem;
  }
}
