@import "/src/styles/fonts";

.button {
  padding: 1rem;
  margin: 2rem 1rem;
  width: 80%;
}

.buttonContainer {
  height: 15rem;
}
