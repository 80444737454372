@import "src/styles/colors";

.linksContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.938rem;
  padding-bottom: 5.688rem;
  padding-top: 1.875rem;
  a {
    color: $black;
    text-decoration: inherit;
  }

  p {
    width: fit-content;
  }

  button {
    padding: 0 !important;
  }
}

.deleteAccount {
  margin-top: 1.25rem;
}
