@import "src/styles/fonts";
@import "src/styles/colors";

.newReleaseContainer {
  height: 100%;
  position: relative;
}

.newReleaseContainer:after {
  display: block;
  padding-bottom: 100%;
  content: "";
}

.newReleaseBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../../icons/boderimage.svg");
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

.newReleaseBox:hover {
  cursor: pointer;
}

.newReleaseImage {
  width: 1.438rem;
  height: 1.438rem;
}

.newReleaseText {
  margin-top: 0.625rem;
  font-weight: bold;
  text-align: center;
  color: $black;
}
