@import "src/styles/fonts";
@import "src/styles/colors";

.releaseBox {
  position: relative;
  width: 100%;
  height: 100%;
}

.releaseBox:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.releaseBox:hover {
  cursor: default;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  -webkit-filter: blur(0.313rem);
  filter: blur(0.313rem);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.textLayer {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  height: 100%;
  padding: 1.563rem 3.375rem 1.938rem 2.5rem;
}

.title,
.listeners {
  display: inline-block;
  width: 100%;
  font-family: $font-family;
  font-weight: bold;
  text-align: left;
  letter-spacing: 0;
  line-height: 3rem;
  color: $white;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
  margin: 0;
}

.littleTitle {
  font-size: $font-size-l;
  line-height: $line-height-l;
}

.listeners {
  display: inline-block;
  max-width: 13.25rem;
}
