@import "src/styles/colors";

.link {
  color: $red;
  text-decoration: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

.underlined {
  text-decoration: underline;
}
