@import "src/styles/colors";
@import "src/styles/fonts";
@import "src/styles/breakpoints";

.walletTrackContainer {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  box-sizing: border-box;
  width: 25rem;
  height: 10rem;
}

.walletTrackRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.walletTrackImage {
  width: 7.188rem;
  height: 7.188rem;
  margin-right: 0.656rem;
}

.walletTimesPlayed {
  line-height: 3rem;
  font-weight: bold;
}

.walletTokens {
  line-height: 3rem;
  font-weight: bold;
  color: $red;
}

.walletNumberSubtitle {
  font-weight: 500;
  line-height: $line-height-xxs;
}

.walletTrackTitle {
  margin-top: 0.313em;
  margin-left: 0.313em;
  font-weight: 600;
  text-align: left;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.walletTrackEqual {
  font-weight: bold;
  margin-bottom: 1.125rem;
}

@media (max-width: $device-sm) {
  .walletTrackContainer {
    width: 100vw;
    padding-left: 2rem;
  }
}
