@import "src/styles/fonts";

.container {
  width: 19.813rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome {
  color: black;
  margin-top: 2rem;
}

.link {
  padding-top: 1rem;
  font-size: $font-size-xs;
}
