@import "src/styles/colors";
@import "src/styles/fonts";

.container {
  padding: 2.5rem 0 5.125rem 0;
}

.uploadImage {
  width: 18.25rem;
  height: 18.25rem;
  background-image: url("../../../icons/boderimage.svg");
  padding: 0.375rem 0.75rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: inherit;
  font-size: $font-size-s;
}

.imagePreview {
  width: 17.25rem;
  height: 17.25rem;
}

.icon {
  max-width: 1.5rem;
  max-height: 1.5rem;
  padding-bottom: 1rem;
}
