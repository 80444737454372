@import "src/styles/fonts";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 19.8125rem;
  height: 100%;
}

.form {
  margin-top: 2.5rem;
}

.title {
  margin-bottom: 2rem;
  line-height: normal;
}

.links {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.link {
  margin-bottom: 1rem;
  font-size: $font-size-xs;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.button {
  height: 3rem;
  font-size: 1rem;
  font-weight: bold;
  width: 14.93rem;
  margin-top: 3.438rem;
}
