@import "src/styles/colors";

.firstDivider,
.secondDivider,
.thirdDivider {
  width: 100%;
  height: 0.625rem;
  background-color: $red;
}

.firstDivider {
  opacity: 0.2;
}
.secondDivider {
  opacity: 0.6;
}
