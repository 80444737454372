@import "src/styles/fonts";

.form {
  margin-top: 0.813rem;
}

.welcome {
  width: 19.8125rem;
  margin-top: 2rem;
}

.input {
  margin-top: 2rem;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.button {
  height: 3rem;
  width: 50%;
  margin-top: 3.3125rem;
  font-size: 1rem;
  font-weight: bold;
}
