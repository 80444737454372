@import "src/styles/fonts";

.form {
  margin-top: 3.25rem;
}

.welcome {
  width: 19.8125rem;
  margin-top: 2rem;
}

.inputContainer {
  margin-bottom: 2rem;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.button {
  height: 3rem;
  font-size: 1rem;
  font-weight: bold;
  width: 50%;
  margin-top: 3.375rem;
}
