@import "src/styles/colors";
@import "src/styles/fonts";

.menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  z-index: 20;
  width: 100vw;
  height: 100%;
  background-color: $red;
  top: 0;
  padding-top: 11rem;
}

.navLink {
  color: $white;
  font-size: 1.625rem;
  line-height: 2rem;
  font-weight: bold;
  display: block;
}
