@import "/src/styles/colors";
@import "/src/styles/fonts";

.title {
  margin: 0;
}

.xl {
  font-size: 2.25rem; //36px
  line-height: $line-height-xl;
}

.l {
  font-size: 1.75rem; //28px
  line-height: $line-height-l;
}

.m {
  font-size: 1.25rem; //20px
  line-height: $line-height-m;
}

.s {
  font-size: 1.125rem; //18px
  line-height: $line-height-s;
}

.xs {
  font-size: 1rem; //16px
  line-height: $line-height-xs;
}
