@import "src/styles/fonts";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 8rem;
}

.links {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  font-size: $font-size-xs;
}

.layout {
  height: max-content;
}
