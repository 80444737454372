.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  padding: 1rem;
}

.thanksMessage {
  width: 16rem;
}

.image {
  width: 6.5rem;
  height: 6.5rem;
}
