@import "src/styles/fonts";
@import "src/styles/colors";

.title {
  font-weight: bold;
  margin-top: 3.75rem;
}

.subTitle {
  margin-top: 3.063rem;
}

.walletImg {
  width: 2rem;
  margin-top: 0.75rem;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.tokens {
  color: $red;
  font-size: 3.5rem;
  line-height: 3rem;
  font-weight: bold;
  margin-top: 1.375rem;
}

.tokensInfo {
  display: block;
  margin-top: 1.688rem;
  margin-bottom: 1.875rem;
  font-size: $font-size-s;
  text-decoration: none;
  color: $black;
}
