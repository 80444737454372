@import "src/styles/fonts";

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.select {
  padding: 0;
}
