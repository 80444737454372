@import "src/styles/colors";
@import "src/styles/fonts";
@import "src/styles/breakpoints";

.tabs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  gap: 2.5rem;
  margin-bottom: 1.375rem;
}

.tab {
  font-family: $font-family;
  font-size: $font-size-xs;
  text-align: center;
  padding: 0;
  margin: 0;
  color: $black;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

.active {
  font-weight: bold;
  padding-bottom: 0.125rem;
  border-bottom: 0.125rem solid $red;
}

@media (max-width: $device-sm) {
  .tabs {
    gap: 1rem;
  }
}
