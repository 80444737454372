.burger {
  margin-left: 3.25rem;
  width: 3.25rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
}

.container {
  cursor: pointer;
}

.burger:hover {
  cursor: pointer;
}

.line {
  padding: 0.313rem 0;
}
