.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  width: 6.5rem;
  height: 6.5rem;
  margin-bottom: 1.875rem;
}

.title {
  margin-bottom: 1rem;
}

.text {
  width: 13.188rem;
}
