@import "src/styles/fonts";
@import "src/styles/colors";

.artistTitle {
  margin-top: 3.8125rem;
  margin-bottom: 4.25rem;
}

.label {
  display: flex;
  justify-content: left;
  font-size: $font-size-s;
}

.inputContainer {
  margin-bottom: 2.5rem;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.button {
  width: 14.937rem;
  height: 2.813rem;
  margin-top: 0.938rem;
}
