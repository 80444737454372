@import "src/styles/colors";
@import "src/styles/fonts";

.container {
  height: 2rem;
  padding-bottom: 2.25rem;
}

.uploadAudio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 19.75rem;
  height: 2rem;
  padding: 0.375rem 0;
  cursor: pointer;
}
.border {
  background-image: url("../../../icons/boderimage.svg");
}

.uploadedAudio {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.progressBar {
  height: 0.375rem;
  max-width: 100%;
  transition: width 1s;
  margin-top: 0.25rem;
  background-color: $red;
}

.labelContainer {
  overflow: hidden;
  text-overflow: ellipsis !important;
  text-align: left;
  cursor: inherit;
}

.label {
  cursor: inherit;
  font-size: $font-size-s;
}
