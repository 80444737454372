$font-family: "Montserrat", sans-serif !important;

$font-size-xl: 1.25rem; //20px
$font-size-l: 1.125rem; //18px
$font-size-m: 1rem; //16px
$font-size-s: 0.875rem; //14px
$font-size-xs: 0.75rem; //12px
$font-size-xxs: 0.625rem; //10px

$line-height-xl: 2.375rem; //38px
$line-height-l: 1.625rem; //26px
$line-height-ml: 1.25rem; //20px
$line-height-m: 1.125rem; //18px
$line-height-s: 1rem; //16px
$line-height-xs: 0.875rem; //14px
$line-height-xxs: 0.812rem; //13px
