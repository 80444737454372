@import "src/styles/breakpoints";

.walletTracksComponent {
  display: grid;
  grid-template-columns: repeat(auto-fit, 25rem);
  grid-template-rows: repeat(auto-fit, 10rem);
  justify-content: left;
  align-items: center;
  gap: 0.313rem;
  margin: 5.625rem 3.188rem;
}

@media (max-width: $device-sm) {
  .walletTracksComponent {
    margin-left: 0;
    margin-right: 0;
  }
}
