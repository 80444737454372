@import "src/styles/breakpoints";

.tracksComponentBox {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  grid-template-rows: repeat(auto-fill, auto);
  justify-content: left;
  align-items: center;
  gap: 0.313rem;
  margin: 5.625rem 0.313rem;
}

@media (max-width: $device-lg) {
  .tracksComponentBox {
    margin-left: 0;
    margin-right: 0;
  }
  
  .tracksComponentBox {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
}
