@import "src/styles/fonts";

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  padding: 1rem;
}

.message {
  width: 13rem;
  line-height: $line-height-m;
}

.image {
  width: 6.5rem;
  height: 6.5rem;
}
