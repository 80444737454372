@import "src/styles/fonts";

.form {
  margin-top: 0.813rem;
}

.input {
  margin-top: 2rem;
  padding-top: 0.5rem;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.button {
  height: 3rem;
  margin-bottom: 2.313rem;
  font-size: 1rem;
  font-weight: bold;
  width: 50%;
}

.formContainer {
  padding-bottom: 1.5rem;
}

.forgotPassword {
  display: flex;
  margin-top: 0.875rem;
  font-size: $font-size-xs;
}

.label {
  font-size: $font-size-xs;
}
