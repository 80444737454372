@import "src/styles/colors";
@import "src/styles/fonts";

.input {
  padding: 0.5rem 1rem 0.5rem 0;
  border: none;
  border-bottom: 0.063rem solid $lightest-red;
  box-sizing: border-box;
  border-radius: 0.5rem 0.5rem 0 0;
  flex: none;
  align-self: stretch;
  width: 100%;
  font-size: $font-size-s;
  font-family: $font-family;
  font-weight: bold;

  &:focus {
    outline: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-webkit-autofill::first-line {
    -webkit-box-shadow: 0 0 0 1.875rem white inset !important;
  }

  &::placeholder {
    font-family: $font-family !important;
    font-weight: bolder;
    color: $black;
  }
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  background: $grey;
  text-decoration: line-through;
  color: $dark-grey;
  border-radius: 0;
  padding-left: 0.5rem;
  margin-top: 0.25rem;

  user-select: none !important;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}
