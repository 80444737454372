@import "/src/styles/fonts";

.paragraph {
  line-height: $line-height-s;
  font-family: $font-family;
  margin: 0;
}

.xl {
  font-size: $font-size-xl;
}

.l {
  font-size: $font-size-l;
}

.m {
  font-size: $font-size-m;
}

.s {
  font-size: $font-size-s;
}

.xs {
  font-size: $font-size-xs;
}

.xxs {
  font-size: $font-size-xxs;
}
