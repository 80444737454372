.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.background {
  background-image: url("/assets/images/dose-background.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
