@import "src/styles/colors";
@import "src/styles/fonts";

.button {
  border: none;
  cursor: pointer;
  transition: background-color 125ms linear;
  padding: 0 1rem;
  font-family: $font-family;
  font-weight: bold;
  overflow: hidden;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
}

.upperCase {
  text-transform: uppercase;
}

.isFullWidth {
  width: 100%;
}

.primary {
  border-radius: 1.563rem;
  background: $red;
  color: $white;
  box-shadow: 0rem 0rem 0rem 0rem $lightest-red;

  transition: box-shadow 100ms ease-in-out;
  &:hover {
    box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem $lightest-red;
  }

  &:active {
    opacity: 0.8;
  }
}

.secondary {
  width: fit-content;
  border-radius: 0.313rem;
  background: $white;
  border: 0.063rem solid $darker-red;
  color: $black;

  &:hover {
    background: $darkest-red;
  }

  &:active {
    opacity: 0.8;
  }

  &.loading:hover {
    background: $white;
  }
}

.cancellation {
  border-radius: 1.563rem;
  background: $black;
  color: $white;
  box-shadow: 0rem 0rem 0rem 0rem $dark-grey;
  transition: box-shadow 100ms ease-in-out;

  &:hover {
    box-shadow: 0.1rem 0.1rem 0.1rem 0.1rem $dark-grey;
  }

  &:active {
    opacity: 0.8;
  }
}

.minimal {
  background: none;
  font-weight: normal;
}

.large {
  font-size: 1.125rem;
  font-weight: 500;
  height: 2.5rem;
  letter-spacing: 0.139rem;
}

.small {
  font-size: 0.563rem;
  font-weight: 500;
  height: 1.5rem;
  letter-spacing: 0.081rem;
}

.disabled {
  &:hover {
    cursor: not-allowed;
  }
}

.spinner {
  display: flex;
  justify-content: center;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $white;
}
