$red: #ff595a;
$darker-red: #ff0202;
$darkest-red: #df0000;
$lighter-red: #ff8282;
$lightest-red: #ffacac;
$white: #ffffff;
$black: #000000;
$dark-grey: #444444;
$grey: #f2f2f2;
$dark-grey: #6b6b6b;
$green: #6ad72f;
