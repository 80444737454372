@import "src/styles/colors";
@import "src/styles/fonts";
@import "src/styles/breakpoints";

.principal {
  background-color: $white;
  color: $red;
  display: flex;
  padding-top: 1rem;
  align-items: center;
  flex-direction: row;
  position: relative;
  width: 100%;
}

.nav {
  display: flex;
  width: 100%;
  justify-content: center;
}

.marginRightAdd {
  margin-right: 6rem;
}

.marginLeftBurger {
  margin-left: 6rem;
}

.logo {
  width: 8.75rem;
  height: 3.813rem;
}

.subtitle {
  font-size: 0.75rem;
  font-weight: bold;
  margin: 0;
}

.background {
  background-color: transparent;
}

.navOpened {
  background-color: $red;
  position: fixed;
  z-index: 50;
}

.links {
  height: fit-content;
}

.closeContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 3.25rem;
  margin-left: 3.25rem;
  height: 3rem;
}

.close {
  position: absolute;
  left: 0;
  top: auto;
  width: 2rem;
  height: 2rem;
}

.close:hover {
  cursor: pointer;
}

.close:before,
.close:after {
  position: absolute;
  left: 0.938rem;
  content: " ";
  height: 2.063rem;
  width: 0.125rem;
  background-color: $white;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.addContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 3.25rem;
  margin-right: 3.25rem;
  padding: 0;
  height: 100%;
}

.addIcon {
  max-width: 1.5rem;
  max-height: 1.5rem;
}

@media (max-width: $device-sm) {
  .addContainer {
    margin-right: 2rem;
  }

  .closeContainer,
  .links {
    margin-left: 2rem;
  }
}
